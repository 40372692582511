import React, { useEffect, useState } from "react";
import { useMap, GridLayer } from "react-leaflet";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import {useLeafletContext} from "@react-leaflet/core";

export default function GeoRaster({ url, sendRasterLayer }) {
  const map = useMap();
  const layerRef = React.useRef(null);
  const [raster, setRaster] = useState();
  const context = useLeafletContext();

  useEffect(() => {
    parseGeoraster(url).then((georaster) => {
      console.log("georaster", georaster);
      setRaster(georaster);
    });
  }, [url]);

  useEffect(() => {
    if (raster) {
      const layer = new GeoRasterLayer({
        attribution: "Planet",
        georaster: raster,
        debugLevel: 0,
        resolution: 128,
        
      });
      layerRef.current = layer;
      const container = context.layerContainer || context.map;

      container.addLayer(layer);
      console.log(layer.getBounds())
      map.fitBounds(layer.getBounds())
      sendRasterLayer(layer)
    }
    return () => {
      if (map && layerRef.current) {
        map.removeLayer(layerRef.current);
      }
    };
  }, [raster, map]);

  return null;
}
