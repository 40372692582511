import React from 'react'
import { useState } from "react";
import './imageCard.css'
import { Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
  
const Card = (props) => {

    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    function handleShow() {
        setFullscreen(true);
        setShow(true);
    }
    return (
        <>
            <div className="ImageCard" onClick={() => handleShow(true)}>
                <img src={props.image} alt="No img"/>
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </div>
            <Modal 
            show={show} 
            fullscreen={fullscreen} 
            onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default Card