import React from 'react'
import './projectGallery.css'


import ProjectCard from '../cards/projectCard'
import seg_bg from "../../assets/wallpaper.jpg"
import obj_det_bg from "../../assets/wallpaper2.jpg"
import {Link} from "react-router-dom";

const projectGallery = (props) => {
  const projects = [
    {
        "key": 1,
        "project_id": 1,
        "title": "SAM Demo: Geo data",
        "text": "2 images",
        "type": "segmentation"
    },
  //   {
  //     "key": 2,
  //     "project_id": 2,
  //     "title": "Demo 2",
  //     "text": "18 images",
  //     "type": "object_detection"
  // }
  ]
  const proj_type_img_map = {
    "segmentation": seg_bg,
    "object_detection": obj_det_bg,
  }
  

  return (
    <div className="ProjectCards">
      <h2>Projects</h2>
      <div className="ProjectCardGroup">
        {projects.map((project) => (
          <Link
          to={{pathname: `/project/${project.project_id}/data`, state: project}}
          style={{textDecoration: 'none'}} 
        >
          <ProjectCard
            key={project.key}
            id={project.project_id}
            title={project.title}
            text={project.text}
            image={proj_type_img_map[project.type]}
          />
        </Link>
            
          ))}
        
      </div>
    </div>
  );
  
};
export default projectGallery