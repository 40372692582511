import React from 'react'
import { useRef, useEffect, useState } from "react";
import './imageCard.css'
import parseGeoraster from "georaster";
import { Modal } from "react-bootstrap";
import Maps from "../label_tool/geo/Maps";

const CardTiff = (props) => {
    // const [imgUrl, setImgUrl] = useState(props.image);
    const [show, setShow] = useState(false);
    // const [rasterValues, setRasterValues] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    function handleShow() {
        setFullscreen(true);
        setShow(true);
      }

    // const ref = useRef(null);
    // useEffect(() => {
    //     parseGeoraster(props.image).then((georaster) => {
    //         // console.log("georaster", georaster);
    //         //   const options = { left: 0, top: 0, right: 4000, bottom: 4000, width: 10, height: 10 }
    //         let height_over_width = georaster.height / georaster.width
    //         // let height = height_over_width >= 1 ? 512 : Math.round(512 / height_over_width);
    //         // let width = height_over_width <= 1 ? 512 : Math.round(512 / height_over_width);
    //         const options = { left: 0, top: 0, right: georaster.width, bottom: georaster.height, width: 250, height: 100}
    //         georaster.getValues(options).then(values => {
    //             // let base64Str = getBase64IntArray(values);
    //             // setImgUrl(`data:image/png;base64,${base64Str}`);
    //             const element = ref.current;
    //             var ctx = element.getContext("2d"); 
    //             var r,g,b; 
    //             for(var i=0; i< 100; i++){ 
    //                 for(var j=0; j< 200; j++){ 
    //                     r = values[0][i][j]; 
    //                     g = values[1][i][j];	 
    //                     b = values[2][i][j];		 
    //                     ctx.fillStyle = "rgba("+r+","+g+","+b+", 1)";  
    //                     ctx.fillRect( j, i, 1, 1 ); 
    //                 } 
    //             } 
    //         })
            
    //     });
    //   }, [props.image]);

    return (
        <>
            <div className="ImageCard" onClick={() => handleShow(true)}>
                <img src={props.image} alt="No img"/>
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </div>
            <Modal 
            show={show} 
            fullscreen={fullscreen} 
            onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Maps
                    img_url={props.cog_tiff_image}
                    >
                    </Maps>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CardTiff