import simplify from "@turf/simplify"

// callback(px:coord => coord)  用于处理不同的offset
export function offsetPoint(coord, offset) {
  if (typeof offset === "function") {
    return offset(coord)
  } else {
    return [coord[0] + offset[0], coord[1] + offset[1]]
  }
}

export function offsetRing(ring, offsets) {
  const newRing = ring.map(coord => {
    return offsetPoint(coord, offsets)
  })
  return newRing
}

export function offsetRings(rings, offsets) {
  const newRings = rings.map(ring => {
    return offsetRing(ring, offsets)
  })
  return newRings
}

export function offsetPolygon(geometry, offsets) {
  const type = geometry.type
  let newCoords = []
  if (type.toLowerCase() !== "polygon") {
    newCoords = geometry.coordinates.map(rings => {
      return offsetRings(rings, offsets)
    })
  } else {
    newCoords = geometry.coordinates.map(polgons => {
      return polgons.map(rings => {
        return offsetRings(rings, offsets)
      })
    })
  }

  console.log(newCoords)

  return {
    type: "Feature",
    properties: {
      value: geometry.value
    },
    geometry: {
      type: geometry.type,
      coordinates: newCoords
    }
  }
}

export function simplifyPolygon(polygon, tolerance = 5) {
  // 局部坐标转全局坐标
  if (tolerance === 0) return polygon
  const simplifyData = simplify(polygon, {
    tolerance: tolerance,
    highQuality: false
  })

  return simplifyData
}
