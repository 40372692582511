import React, { useEffect, useState } from "react";
import { env, InferenceSession, Tensor } from 'onnxruntime-web';
import osm from "./osm-providers";
import {MapContainer, TileLayer, useMapEvents, FeatureGroup, Marker, Popup, Polygon} from "react-leaflet";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "../geo/styles.css";
import { SAMGeo } from "../geo/sam-geo";
import GeoRaster from "./CogRaster";
import * as L from "leaflet"
import "leaflet-draw/dist/leaflet.draw.css"
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { EditControl } from 'react-leaflet-draw';

import decoder_model from "../../../assets/models/sam_decoder.onnx"
import _ from 'lodash';
import npyjs from 'npyjs';


function AddMarkerToClick(props) {

  const {imgUrl} = props
  const [mapBounds, setMapBounds] = useState({});
  const [imageEmbedding, setImageEmbeddings] = useState([]);
  const [imageHeight, setImageHeight] = useState([]);
  const [imageWidth, setImageWidth] = useState([]);
  const [decoderModel, setModel] = useState();
  const [currentState, setCurrentState] = useState('');
  const currentStateRef = useRef(currentState);

  const [allPolygons, setAllPolygons] = useState([]);
  const allPolygonsRef = useRef(allPolygons);

  const [editInProgress, setEditInProgress] = useState(false);
  const editInProgressRef = useRef(editInProgress);

  const ref = useRef(null);
  const samGeoObj = new SAMGeo({});

  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const selectedPolygonRef = useRef(selectedPolygon);

  // Function to handle polygon selection
  const handlePolygonSelection = (e) => {
    setSelectedPolygon(e.target);
    selectedPolygonRef.current = e.target
  };
  
  const handleSaveChanges = () => {
    // You can perform any action here when the user clicks the "Save" button
    console.log('Changes saved:');
  };

  // const handlePolygonDragEnd = (index, newCoordinates) => {
  //   setPolygons(prevPolygons => {
  //     const updatedPolygons = [...prevPolygons];
  //     updatedPolygons[index] = newCoordinates;
  //     return updatedPolygons;
  //   });
  // };

  // const delay = ms => new Promise(
  //   resolve => setTimeout(resolve, ms)
  // );
  
  useEffect(() => { fetchModel() }, [])
  
  // useEffect(() => {
  //   console.log(allPolygons)
  // }, [allPolygons]);

  async function predict(clickPointX, clickPointY, img_height, img_width, imgEmbeddingTensorArg) {
    
    console.log("clickPointX and clickPointY")
    console.log(clickPointX)
    console.log(clickPointY)
    try {
      if (
        decoderModel === null
      ) {
        console.log("Model not fetched")
      }
        // Preapre the model input in the correct format for SAM.
        // The modelData function is from onnxModelAPI.tsx.
        // Create the tensor
        let n = 1

        let pointCoords = new Float32Array(2 * (n + 1));
        let pointLabels = new Float32Array(n + 1);

        // Add clicks and scale to what SAM expects
        for (let i = 0; i < n; i++) {
          pointCoords[2 * i] = clickPointX;
          pointCoords[2 * i + 1] = clickPointY;
          pointLabels[i] = 1;
        }

        // Add in the extra point/label when only clicks and no box
        // The extra point is at (0, 0) with label -1
        pointCoords[2 * n] = 0.0;
        pointCoords[2 * n + 1] = 0.0;

        const pointCoordsTensor = new Tensor('float32', pointCoords, [1, n + 1, 2]);
        const pointLabelsTensor = new Tensor('float32', pointLabels, [1, n + 1]);
        const imageSizeTensor = new Tensor('float32', [
          img_width,
          img_width,
        ]);
        // There is no previous mask, so default to an empty tensor
        const maskInput = new Tensor(
          'float32',
          new Float32Array(256 * 256),
          [1, 1, 256, 256],
        );
        // There is no previous mask, so default to 0
        const hasMaskInput = new Tensor('float32', [0]);

        const feeds = {
          image_embeddings: imgEmbeddingTensorArg,
          point_coords: pointCoordsTensor,
          point_labels: pointLabelsTensor,
          orig_im_size: imageSizeTensor,
          mask_input: maskInput,
          has_mask_input: hasMaskInput,
        };
        if (feeds === undefined) return;
        // Run the SAM ONNX model with the feeds returned from modelData()
        let results;
        results = await decoderModel.run(feeds);
        const output = results[decoderModel.outputNames[0]];
        return output;
      
    } catch (e) {
      console.log(e);
      setCurrentState('Unable to predict');
      return;
    }
  }

  async function fetchModel() {
    try{
      setCurrentState('Fetching Model');
      // let modelUrl = 'https://mdn.alipayobjects.com/huamei_qa8qxu/afts/file/A*eRf_QauRmqoAAAAAAAAAAAAADmJ7AQ/sam_onnx_example.glb'
      // env.wasm.wasmPaths = 'https://npm.elemecdn.com/onnxruntime-web/dist/';
      await InferenceSession.create(decoder_model, {executionProviders: ['wasm']})
      .then(
        curModel => {
          setModel(curModel);
          setCurrentState('Model received');
        }
      )
      .catch(err => {
        setCurrentState('Error while fetching Decoder Model');
      })
    }
    catch (e) {
      setCurrentState('Unable to fetch model');
      return
    }
  }

  async function fetchEmbeddings(curMapBounds) {
    try {
      const data = {}
      data["imageURL"] = imgUrl
      data["mapBounds"] = curMapBounds
      let EMBEDDING_URL = process.env.REACT_APP_SAM_SERVICE_EMBEDDING_API_URL
      console.log("EMBEDDING_URL")
      console.log(EMBEDDING_URL)
      setCurrentState('Fetching Embedding');
      setMapBounds(curMapBounds)
      // const action = EMBEDDING_URL;
      let tensor, img_height, img_width;
      await axios.post(EMBEDDING_URL, data)
      .then(buffer => {
        // let arr_length = res.data.embedding.length;
        // let buffer = new ArrayBuffer( arr_length * 2 );
        // set embedding to model
        let array = buffer.data.embedding
        img_height = buffer.data.height
        img_width = buffer.data.width
        tensor = new Tensor('float32', array.flat(3), [1, 256, 64, 64]);

      })
      .catch(err => {
        setCurrentState('Error while fetching embeddings from Server');
      })
      return [tensor, img_height, img_width];
    } catch (error) {
      setCurrentState('Error while fetching embeddings');
      return
    }
  }

  

  const map = useMapEvents({
     async click(e) {
      try{
        if (editInProgress){
          return
        }
        console.log(currentState)
        
        const clickLatLng = e.latlng
        console.log(clickLatLng)
        const clickLat = clickLatLng.lat
        const clickLng = clickLatLng.lng

        var map_bounds = map.getBounds()
        var map_north_east = map_bounds._northEast
        var map_south_west = map_bounds._southWest
        let curMapBounds = {
          "north_east": 
            {"lat": map_north_east.lat, "lng": map_north_east.lng},
          "south_west": 
            {"lat": map_south_west.lat, "lng": map_south_west.lng}
          }
  
        var extent = [map_north_east.lng, map_north_east.lat, map_south_west.lng, map_south_west.lat];
  
        let img_embedding_tensor, img_height, img_width;
        
        if (!(_.isEqual(mapBounds, curMapBounds))){
          setCurrentState('Fetching embedding');
          currentStateRef.current = 'Fetching embedding'
  
          const values = await fetchEmbeddings(curMapBounds);
          img_embedding_tensor = values[0]
          img_height = values[1]
          img_width = values[2]
          
          setImageEmbeddings(img_embedding_tensor);
          setImageHeight(img_height)
          setImageWidth(img_width)
          setCurrentState('Embedding received');
          currentStateRef.current = 'Fetching embedding'
        }
        else {
          img_embedding_tensor = imageEmbedding;
          img_height = imageHeight
          img_width = imageWidth
        }
        // point 1
        let clickPointX, clickPointY;
        clickPointX = Math.round((clickLng - map_south_west.lng)/(map_north_east.lng - map_south_west.lng)*img_width)
        clickPointY = Math.round((clickLat - map_north_east.lat)/(map_south_west.lat - map_north_east.lat)*img_height)
        let output;
        output = await predict(clickPointX, clickPointY, img_height, img_width, img_embedding_tensor);
        samGeoObj.setGeoImage(
          extent,
          img_height,
          img_width,
        );
        console.log("final output")
        console.log(output)
        // point 2
        let vector_points = await samGeoObj.exportGeoPolygon(output)
        let polygon_points = []
        if (vector_points.geometry.type == "MultiPolygon") {
          polygon_points = samGeoObj.flattenMultiPolygonList(vector_points)
        }
        console.log("polygon_points")
        console.log(polygon_points)

        let polygon_point_coords = samGeoObj.getPolygonCoordsFromVectorPoints(
          polygon_points, img_width, img_height, map_north_east, map_south_west)
        console.log("polygon_point_coords")
        console.log(polygon_point_coords)
        setAllPolygons(allPolygons => [...allPolygons, ...polygon_point_coords] );
        // setAllPolygons(new_polygon)
        
        setCurrentState('Done')
        currentStateRef.current = 'Done'
      } catch(error){
          console.error('An error occurred:', error);
      }
    },
    zoomend() { // zoom event (when zoom animation ended)
      const zoom = map.getZoom(); // get current Zoom of map
      console.log("zoom level")
      console.log(zoom)
      // if (geoLayer){
      //   let active_tiles =  geoLayer.getActiveTiles()
      //   let a_tile = geoLayer.getActiveTiles()[0]
      //   a_tile.el.toDataURL()
      // }
    },
    unusedMethodclickFormulas(e) {
      const clickLatLng = e.latlng
      // setMarkers([...markers, newMarker]);
      // var polygon = L.polygon(markers, {color: 'red'});
      // polygon.addTo(map)
      console.log(clickLatLng)
      // var w = geoLayer.width
      // var h = geoLayer.height
      // console.log(map.project(e.latlng, map.getZoom()))
      // var mapWidth=map._container.offsetWidth;
      // var mapHeight=map._container.offsetHeight;
      // var click_x = e.containerPoint.x * w / mapWidth
      // var click_y = e.containerPoint.y * h / mapHeight

      // var north_east = geoLayer.getBounds()._northEast
      // var south_west = geoLayer.getBounds()._southWest

      // var lat_width = north_east.lat - south_west.lat
      // var lng_width = north_east.lng - south_west.lng

      var map_bounds = map.getBounds()
      var map_north_east = map_bounds._northEast
      var map_south_west = map_bounds._southWest

      // var click_y = (north_east.lat - e.latlng.lat)/lat_width*h
      // var click_x = (e.latlng.lng - south_west.lng)/lng_width*w
      console.log("map_north_east")
      console.log(map_north_east)
      console.log("map_south_west")
      console.log(map_south_west)

      // var top = (north_east.lat - map_north_east.lat)/lat_width*h
      // var left = (map_south_west.lng - south_west.lng)/lng_width*w
      // var right = (map_north_east.lng - south_west.lng)/lng_width*w
      // var bottom = (north_east.lat - map_south_west.lat)/lat_width*h
      
      // const options = { left: left, top: top, right: right, bottom: bottom, width: 300, height: 100}
      // console.log(options)
    //   geoLayer.georasters[0].getValues(options).then(values => {
    //     console.log('clipped values')
    //     console.log(values)
    //     // let base64Str = getBase64IntArray(values);
    //     // setImgUrl(`data:image/png;base64,${base64Str}`);
    //     // const element = ref.current;
    //     // var ctx = element.getContext("2d"); 
    //     // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    //     // var r,g,b; 
    //     // for(var i=0; i< 100; i++){ 
    //     //     for(var j=0; j< 300; j++){ 
    //     //         r = values[0][i][j]; 
    //     //         g = values[1][i][j];	 
    //     //         b = values[2][i][j];		 
    //     //         ctx.fillStyle = "rgba("+r+","+g+","+b+", 1)";  
    //     //         ctx.fillRect( j, i, 1, 1 ); 
    //     //     } 
    //     // } 
    //     // console.log(ctx.canvas.toDataURL())
    // })


    },
  })

  // const handlePolygonEdit = (index, newCoordinates) => {
  //   setAllPolygons(prevPolygons => {
  //     const updatedPolygons = [...prevPolygons];
  //     updatedPolygons[index] = newCoordinates;
  //     return updatedPolygons;
  //   });
  // };
  // Function to handle edit start
  const handleEditStart = () => {
    setEditInProgress(true);
  };

  // Function to handle edit end
  const handleEditEnd = () => {
    setEditInProgress(false);
  };

  return (
    <>
    <FeatureGroup>
      {/* Add EditControl for polygon editing */}
      <EditControl
          position='topright'
          draw={{
            rectangle: false,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: true,
          }}
          onCreated={handleSaveChanges}
          edit={{
            edit: true,
            remove: true,
            featureGroup: {
              type: 'FeatureCollection',
              features: allPolygons.map((coordinates, index) => ({
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates: [coordinates]
                }
              }))
            }
          }}
          onEdited={(e) => {
            // e.layers.eachLayer(layer => {
            //   const index = layer._leaflet_id - 1; // Get the index of the edited polygon
            //   handlePolygonEdit(index, layer.getLatLngs()[0]); // Update the coordinates of the polygon
            // });
            handleEditEnd(); // Handle edit end
          }}
          onEditStart={handleEditStart} // Handle edit start
      />
      <>
        {/* Render the polygon using the coordinates from state */}
        {allPolygons.map(
          (coordinates, index) => (
            <Polygon
              key={index} 
              positions={coordinates} 
              eventHandlers={{
                click: handlePolygonSelection, // Assign click event handler to handlePolygonSelection
                // edit: (e) => handlePolygonEdit(index, e.target.getLatLngs()) // Handle edit event
              }}
            />
          )
        )}
      </>

      {
      (currentState !== 'Model received' && currentState !== 'Done' && currentState !== 'Embedding received' )  ?
        (<>
        <div className="custom-clip-loader">
          <ClipLoader  />
          <p>{currentState}</p>
        </div> 
      </>) : <></>
      }
      
      
    </FeatureGroup>
    </>
  )
}

const Maps = (props) => {
  const ZOOM_LEVEL = 9;
  const mapRef = useRef();
  const [geoLayer, setGeoLayer] = useState();
  // const [raster, setRaster] = useState();

  const setRasterLayer = (layer) => {
    console.log("setRasterLayer")
    console.log(layer.getBounds());
    setGeoLayer(layer)
    // setRaster(raster)
  };

  // this.setRasterLayer = this.setRasterLayer.bind(this);

  return (
    <>
      <MapContainer zoom={ZOOM_LEVEL} ref={mapRef}>
          <TileLayer
              url={osm.maptiler.url}
              attribution={osm.maptiler.attribution}
          />
          <GeoRaster 
              url={props.img_url}
              sendRasterLayer={setRasterLayer}
              >
          </GeoRaster>
          <AddMarkerToClick imgUrl={props.img_url} />
      </MapContainer>
    </>
  );
}

export default Maps;
