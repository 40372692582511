import { SAM } from "./sam"
import { MapHelper } from "./utils/geo"
import { offsetPolygon } from "./utils/vector"

export class SAMGeo extends SAM {
  metersPerpixelsX = 0

  metersPerpixelsY = 0

  mapHelper = new MapHelper(256, "google")

  async setGeoImage(extent, width, height) {
    // super.setImage(image)
    // this.imageOption = imageOption
    // const { extent, width, height } = imageOption
    this.imageHeight = height
    if (extent) {
      this.imageBounds = [
        ...this.mapHelper.lngLatToMeters(extent[0], extent[1]),
        ...this.mapHelper.lngLatToMeters(extent[2], extent[3])
      ]
      this.metersPerpixelsX =
        (this.imageBounds[2] - this.imageBounds[0]) / width
      this.metersPerpixelsY =
        (this.imageBounds[3] - this.imageBounds[1]) / height
    }
  }

  async exportGeoPolygon(output, simplifyThreshold = 5) {
    const vector = await this.exportVector(output, simplifyThreshold)
    // const bounds = this.imageBounds

    // const polygon = offsetPolygon(vector.geometry, ([x, y]) => {
    //   const px = [
    //     x * this.metersPerpixelsX + bounds[0],
    //     (this.imageHeight - y) * this.metersPerpixelsY + bounds[1]
    //   ]

    //   // 墨卡托转经纬度
    //   const lnglat = this.mapHelper.metersToLngLat(px[0], px[1])
    //   return lnglat
    // })
    // // 图片像素坐标转经纬度坐标;
    // 图表像素坐标->墨卡托->经纬度
    console.log(vector)
    // return {
    //   type: "FeatureCollection",
    //   features: [polygon]
    // }
    return vector
  }
  // 瓦片场景
  flattenMultiPolygonList(vector_points){
    let flattened_list = []
    let main_list = vector_points.geometry.coordinates
    for (let i=0;i < main_list.length; i++){
      let cur_multi_polygon_list =  main_list[i]
      for (let j=0;j < cur_multi_polygon_list.length; j++){
        // if needed, add validation to check if each element within
        // polygon_points have length of 2 only
        let polygon_points = cur_multi_polygon_list[j]
        flattened_list.push(polygon_points)
      }
    }
    return flattened_list
  }

  getPolygonCoordsFromVectorPoints(polygon_points_list, width, height, north_east_point, south_west_point){
    var lat1 = north_east_point.lat
    var lng2 = north_east_point.lng
    var lat2 = south_west_point.lat
    var lng1 = south_west_point.lng
    let polygon_coords_list = []
    let coord, px, py, lngp, latp;
    for (let i=0;i<polygon_points_list.length;i++){
      let cur_polygon = polygon_points_list[i]
      let cur_polygon_cords = []
      for (let j =0; j<cur_polygon.length; j++){
        px = cur_polygon[j][0]
        py = cur_polygon[j][1]

        lngp = lng1 + px/width * (lng2-lng1)
        latp = lat1 + py/height * (lat2-lat1)
        coord = [latp, lngp]
        cur_polygon_cords.push(coord)
      }
      polygon_coords_list.push(cur_polygon_cords)
    }
    return polygon_coords_list
  }

  lngLat2ImagePixel(lnglat) {
    if (!this.imageBounds) return
    const [x, y] = this.mapHelper.lngLatToMeters(lnglat[0], lnglat[1])
    const dx = (x - this.imageBounds[0]) / this.metersPerpixelsX
    let dy = (y - this.imageBounds[1]) / this.metersPerpixelsY
    dy = this.imageOption.height - dy
    // Todo: 超出范围处理
    return [dx, dy]
  }
}
