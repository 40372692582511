import React from 'react'
import './imageGallery.css'

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../Loading";
import Card from "../cards/imageCard"
import CardTiff from '../cards/imageCardTiff';

const images = [
  {
    "cog_tif_url": "https://storage.googleapis.com/pdd-stac/disasters/hurricane-harvey/0831/20170831_172754_101c_3b_Visual.tif",
    "url": "https://mldesk-project-demo.s3.eu-north-1.amazonaws.com/grassland_tiff_sample.png",
    "labelling_status": "Unlabelled"
  },
  {
    "cog_tif_url": "https://sentinel-cogs.s3.us-west-2.amazonaws.com/sentinel-s2-l2a-cogs/36/Q/WD/2020/7/S2A_36QWD_20200701_0_L2A/TCI.tif",
    "url": "https://mldesk-project-demo.s3.eu-north-1.amazonaws.com/desert_tiff_dample.png",
    "labelling_status": "Unlabelled"
  },
]

const getImageExt = (img_url) => {
  if (img_url == null){
    return
  }
  return img_url.split(/[#?]/)[0].split('.').pop().trim();
  };

const ImageGallery = (props) => {
  // implement Infinite scroll here: import InfiniteScroll from 'react-infinite-scroll-component';
  const { state } = props.location;
  const { title } = state;

  
  return (
    
    <div className="ImageCards">
      <h2>{title}</h2>
      <div className="ImageCardGroup">
        {images.map((image) => (
          
          getImageExt(image.cog_tif_url) === "tif"?
            <CardTiff
            title={image.title}
            labelling_status={image.labelling_status}
            image={image.url}
            cog_tiff_image={image.cog_tif_url}
          />
            :
            <Card
            title={image.title}
            labelling_status={image.labelling_status}
            image={image.url}
          />
            
          ))}
      </div>
    </div>
  );
  
};

export default withAuthenticationRequired(ImageGallery, {
  onRedirecting: () => <Loading />,
});
