import React from "react";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import ProjectGalllery from "../components/projectsGallery/projectGallery";
const Projects = () => {

  return (
    <div>
        <ProjectGalllery></ProjectGalllery>
    </div>
  );
};


export default withAuthenticationRequired(Projects, {
  onRedirecting: () => <Loading />,
});